import React from "react"
import { FaTimes } from "react-icons/fa"
import { MySocialLinks } from "../constants/MySocialLinks"
import RouteLinks from "../constants/RouteLinks"

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <aside className={`sidebar ${isOpen && "show-sidebar"}`}>
      <button onClick={toggleSidebar} className="close-btn">
        <FaTimes />
      </button>

      <div className="side-container">
        <RouteLinks styleClass={isOpen && "sidebar-links"} />
        <MySocialLinks styleClass={isOpen && "sidebar-icons"} />
      </div>
    </aside>
  )
}

export default Sidebar
