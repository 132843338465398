import React from "react"
import RouteLinks from "../constants/RouteLinks"
import { FaAlignRight } from "react-icons/fa"

const Navbar = ({ toggleSidebar }) => {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <span>AAZ</span>
          <button type="button" onClick={toggleSidebar} className="toggle-btn">
            <FaAlignRight />
          </button>
        </div>
        <RouteLinks styleClass="nav-links" />
      </div>
    </nav>
  )
}

export default Navbar
