import React from "react"
import { MySocialLinks } from "../constants/MySocialLinks"

const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <MySocialLinks styleClass="footer-links" />
        <h4>copyright&copy;{new Date().getFullYear()}</h4>
        <span>AAZ</span> all rights reserved
      </div>
    </footer>
  )
}

export default Footer
